exports.components = {
  "component---src-components-producto-jsx": () => import("./../../../src/components/Producto.jsx" /* webpackChunkName: "component---src-components-producto-jsx" */),
  "component---src-components-productos-jsx": () => import("./../../../src/components/Productos.jsx" /* webpackChunkName: "component---src-components-productos-jsx" */),
  "component---src-components-refacciones-jsx": () => import("./../../../src/components/Refacciones.jsx" /* webpackChunkName: "component---src-components-refacciones-jsx" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-contacto-jsx": () => import("./../../../src/pages/contacto.jsx" /* webpackChunkName: "component---src-pages-contacto-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-nosotros-jsx": () => import("./../../../src/pages/nosotros.jsx" /* webpackChunkName: "component---src-pages-nosotros-jsx" */)
}

